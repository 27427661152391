import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';

const AddProperty = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      property_type: '',
      villaName: '',
      villaHeadline: '',
      villaContent: '',
      max_adults: '',
      max_child: '',
      max_bedrooms: '',
      max_bathrooms: '',
      location: '',
      price: '',
      taxes: '18%',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(API_URL + '/addProperty', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            if (response.ok) {
                alert('Property added successfully');
                navigate('/PropertyDatabase');
            } else {
                alert(`Failed to add property: ${result.error}`);
            }
        } catch (error) {
            console.error('Error adding property:', error);
            alert('Failed to add property');
        }
    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>Add Property</p>
                <form onSubmit={handleSubmit}>
                    <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Type</p>
                            <select 
                                name='property_type'
                                value={formData.property_type}
                                onChange={handleChange}
                                className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                                style={{ appearance: 'none' }}
                            >
                                <option value=''  disabled hidden>Select</option>
                                <option value='Villa'>Villa</option>
                                <option value='Apartment'>Apartment</option>
                            </select>
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Name</p>
                            <input 
                                type='text'
                                name='villaName'
                                value={formData.villaName}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Headline</p>
                            <input 
                                type='text'
                                name='villaHeadline'
                                value={formData.villaHeadline}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Content</p>
                            <input 
                                type='text'
                                name='villaContent'
                                value={formData.villaContent}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Near By Beach</p>
                            <input 
                                type='text'
                                name='location'
                                value={formData.location}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Price/ Night</p>
                            <input 
                                type='text'
                                name='price'
                                value={formData.price}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Adults</p>
                            <input 
                                type='text'
                                name='max_adults'
                                value={formData.max_adults}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Child</p>
                            <input 
                                type='text'
                                name='max_child'
                                value={formData.max_child}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Bedrooms</p>
                            <input 
                                type='text'
                                name='max_bedrooms'
                                value={formData.max_bedrooms}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Bathrooms</p>
                            <input 
                                type='text'
                                name='max_bathrooms'
                                value={formData.max_bathrooms}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Taxes</p>
                            <input 
                                type='text'
                                name='taxes'
                                value={formData.taxes}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            />
                        </div>
                    </div>

                    <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                        <button type='button' className='cancel_btn' onClick={() => navigate('/PropertyDatabase')}>Cancel</button>
                        <button type='submit' className='done_btn'>Done</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddProperty;
