import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';

const AddCoupon = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    coupon_name: '',
    discount_percentage: '',
    expiryDate: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(API_URL + '/addCoupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (response.ok) {
        alert('Coupon added successfully');
        navigate('/GenerateCoupon');
      } else {
        alert(`Failed to add coupon: ${result.error}`);
      }
    } catch (error) {
      console.error('Error adding coupon:', error);
      alert('Failed to add coupon');
    }
  };

  return (
    <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
      <Menu />
      <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
        <p className='mb-[2%] font-semibold'>Add Coupon</p>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
            <div className='m-[2%] lg:mx-[5%]'>
              <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Coupon Name</p>
              <input
                type='text'
                name='coupon_name'
                value={formData.coupon_name}
                onChange={handleChange}
                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
              />
            </div>

            <div className='m-[2%] lg:mx-[5%]'>
              <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Discount Percentage</p>
              <input
                type='text'
                name='discount_percentage'
                value={formData.discount_percentage}
                onChange={handleChange}
                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
              />
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
            <div className='m-[2%] lg:mx-[5%]'>
              <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Expiry Date</p>
              <input
                type='date'
                name='expiryDate'
                value={formData.expiryDate}
                onChange={handleChange}
                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
              />
            </div>
          </div>

          <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
            <button type='button' className='cancel_btn' onClick={() => navigate('/UserDatabase')}>
              Cancel
            </button>

            <button type='submit' className='done_btn'>
              Done
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCoupon;
