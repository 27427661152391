import React, { useState } from 'react';
import './Menu.css';
import { PiHouseLine } from "react-icons/pi";
import { IoMdCloudOutline } from "react-icons/io";
import { LuCalendarDays } from "react-icons/lu";
import { PiNotebookBold } from "react-icons/pi";
import { RiCouponLine } from "react-icons/ri";
// import { IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { Tooltip } from "antd";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { HiOutlineUserCircle } from "react-icons/hi2";


const Menu = () => {

    const {user} = useAuth();
    const username = user.user;
    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    const [isChanged, setIsChanged] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleChange = () => {
        setIsChanged(prevState => !prevState);
        setMenuOpen(!menuOpen);
    };

    const handleLogout = () => {
        try {
            sessionStorage.removeItem('user');
            window.location.reload(true);
            window.location.href = '/Login'
        } catch (error) {
            console.error('Error removing user data from localStorage:', error);
        }
    }

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>

            {/* ToggleMenu for mobile starts from here */}
            <div className='lg:hidden flex items-center mx-[3%] md:mx-[2%]'>
                <div className='my-[3%] md:my-[2%]'>
                    <div className={isChanged ? "changebar" : ""} onClick={handleToggleChange} style={{ position: 'relative', zIndex: 100 }}>
                        <div className="menubar1"></div>
                        <div className="menubar2"></div>
                        <div className="menubar3"></div>
                    </div>

                    <div className='menu_bg h-[100vh] w-[50vw] md:w-[40vw]' style={{ left: menuOpen ? 0 : '-100vw', top: 0, transition: 'left 0.5s ease-in-out', zIndex: 90, position: 'fixed' }}>
                        
                        <div className='flex justify-center text-white leading-5 2xl:leading-9 mt-[10vh] -mb-[15vh]'>
                            <div className='flex items-center'>
                                <HiOutlineUserCircle className='text-3xl' />
                                <p className='ml-2'>Hello, <br/><span className='uppercase'>{username.firstName || ''} {username.lastName || ''}</span></p>
                            </div>
                        </div>
                        
                        <div className='flex flex-col h-full items-center justify-center text-white'>
                            <a href="/" className='flex items-center w-[70%]'>
                                <PiHouseLine className={`text-[9vw] md:text-[6vw] my-[1.5vh] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/Dashboard') || isActive('/')  ? 'bg-white text-black rounded-full' : ''}`} />
                                <span className='ml-[10%] font-semibold'>Dashboard</span>
                            </a>

                            <a href="/MyCalendar" className='flex items-center w-[70%]'>
                                <LuCalendarDays className={`text-[9vw] md:text-[6vw] my-[1.5vh] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/MyCalendar') ? 'bg-white text-black rounded-full' : ''}`} />
                                <span className='ml-[10%] font-semibold'>Calendar</span>
                            </a>

                            <a href="/ManualBooking" className='flex items-center w-[70%]'>
                                <PiNotebookBold className={`text-[9vw] md:text-[6vw] my-[1.5vh] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/ManualBooking') ? 'bg-white text-black rounded-full' : ''}`} />
                                <span className='ml-[10%] font-semibold'>Booking</span>
                            </a>

                            <a href="/GenerateCoupon" className='flex items-center w-[70%]'>
                                <RiCouponLine className={`text-[9vw] md:text-[6vw] my-[1.5vh] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/GenerateCoupon') ? 'bg-white text-black rounded-full' : ''}`} />
                                <span className='ml-[10%] font-semibold'>Coupon</span>
                            </a>

                            <Tooltip title={<><a href='/UserDatabase' style={{ color: 'white' }}>User Database</a><br /><a href='/PropertyDatabase' style={{ color: 'white' }}>Property Database</a></>} placement="right">
                                <div className='flex items-center w-[70%]'>
                                    <IoMdCloudOutline className={`text-[9vw] md:text-[6vw] my-[1.5vh] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/UserDatabase') || isActive('/PropertyDatabase') ? 'bg-white text-black rounded-full' : ''}`} />
                                    <span className='ml-[10%] font-semibold'>Database</span>
                                </div>
                            </Tooltip>

                            {/* <a href="/Settings" className='flex items-center w-[70%]'>
                                <IoSettingsOutline className={`text-[9vw] md:text-[6vw] my-[1.5vh] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/Settings') ? 'bg-white text-black rounded-full' : ''}`} />
                                <span className='ml-[10%] font-semibold'>Settings</span>
                            </a> */}

                            <button onClick={handleLogout} className='flex items-center w-[70%] mt-[15vh]'>
                                <MdLogout className='text-[9vw] md:text-[6vw] p-[3%] transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black' />
                                <span className='ml-[10%] font-semibold'>Logout</span>
                            </button>
                        </div>
                    </div>
                </div>

                <a href='/Dashboard' className='flex ml-auto my-[3%] md:my-[2%]'>
                    <img className='h-8 md:h-10 2xl:h-14' src="../assets/soham_logo_new.webp" alt="Img" style={{ zIndex: 200 }} />
                </a>
            </div>
            {/* ToggleMenu for mobile screens ends here */}

            {/* SideMenu for big screen starts from here */}
            <div className='hidden lg:block'>
                <div className='flex'>
                    <div className='fixed bg-black flex flex-col w-[8%] items-center justify-center h-screen text-white overflow-y-auto' style={{ borderRadius: '0 1.5vw 1.5vw 0' }}>

                        <Tooltip title="Dashboard" placement="right">
                            <a href="/Dashboard">
                                <PiHouseLine className={`lg:text-[3.5vw] 2xl:text-[3vw] lg:my-[1vh] 2xl:my-[2vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/Dashboard') || isActive('/')  ? 'bg-white text-black rounded-full' : ''}`} />
                            </a>
                        </Tooltip>

                        <Tooltip title="Calendar" placement="right">
                            <a href="/MyCalendar">
                                <LuCalendarDays className={`lg:text-[3.5vw] 2xl:text-[3vw] lg:my-[1vh] 2xl:my-[2vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/MyCalendar') ? 'bg-white text-black rounded-full' : ''}`} />
                            </a>
                        </Tooltip>

                        <Tooltip title="Booking" placement="right">
                            <a href="/ManualBooking">
                                <PiNotebookBold className={`lg:text-[3.5vw] 2xl:text-[3vw] lg:my-[1vh] 2xl:my-[2vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/ManualBooking') ? 'bg-white text-black rounded-full' : ''}`} />
                            </a>
                        </Tooltip>

                        <Tooltip title="Coupon" placement="right">
                            <a href="/GenerateCoupon">
                                <RiCouponLine className={`lg:text-[3.5vw] 2xl:text-[3vw] lg:my-[1vh] 2xl:my-[2vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/GenerateCoupon') ? 'bg-white text-black rounded-full' : ''}`} />
                            </a>
                        </Tooltip>

                        <Tooltip title={<><a href='/UserDatabase' style={{ color: 'white' }}>User Database</a><br /><a href='/PropertyDatabase' style={{ color: 'white' }}>Property Database</a></>} placement="right">
                            <div>
                                <IoMdCloudOutline className={`lg:text-[3.5vw] 2xl:text-[3vw] lg:my-[1vh] 2xl:my-[2vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/UserDatabase') || isActive('/PropertyDatabase') ? 'bg-white text-black rounded-full' : ''}`} />
                            </div>
                        </Tooltip>

                        {/* <Tooltip title="Settings" placement="right">
                            <a href="/Settings">
                                <IoSettingsOutline className={`lg:text-[3.5vw] 2xl:text-[3vw] lg:my-[1vh] 2xl:my-[2vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black ${isActive('/Settings') ? 'bg-white text-black rounded-full' : ''}`} />
                            </a>
                        </Tooltip> */}

                        <Tooltip title="" placement="right">
                            <button onClick={handleLogout} >
                                <MdLogout className='lg:text-[3.5vw] 2xl:text-[3vw] mt-[10vh] p-2 transform transition-transform hover:scale-110 hover:bg-white hover:rounded-full hover:text-black' />
                            </button>
                        </Tooltip>
                    </div>

                    <div className='flex items-center w-full'>
                        <a href='/Dashboard' className='flex mx- my-[2%] ml-[48%]'>
                            <img className='h-8 md:h-10 2xl:h-14' src="../assets/soham_logo_new.webp" alt="Img" />
                        </a>

                        <div className='text-[#877D6F] leading-5 2xl:leading-6 ml-[23%] 2xl:ml-[26%] flex items-center'>
                            <HiOutlineUserCircle className='text-4xl 2xl:text-6xl' />
                            <div className='ml-2 2xl:ml-4'>
                                <p>Hello, <span className='uppercase text-black'>{username.firstName || ''} {username.lastName || ''}</span></p>
                                <p>Good to see you here today!</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* SideMenu for big screen starts from here  */}
        </div>
    );
};

export default Menu;
