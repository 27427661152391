import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';
import axios from 'axios';

const EditProperty = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const propertyDetails = location.state?.propertyDetails || '';

    const [formData, setFormData] = useState({
        id: propertyDetails.id || '',
        property_type: propertyDetails.property_type || '',
        villaName: propertyDetails.villaName || '',
        villaHeadline: propertyDetails.villaHeadline || '',
        villaContent: propertyDetails.villaContent || '',
        max_adults: propertyDetails.max_adults || '',
        max_child: propertyDetails.max_child || '',
        max_bedrooms: propertyDetails.max_bedrooms || '',
        max_bathrooms: propertyDetails.max_bathrooms || '',
        location: propertyDetails.nearby_beach || '',
        price: propertyDetails.price || '',
        taxes: propertyDetails.taxes || '18%',
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const updateProperty = async () => {
            try {
                const response = await axios.put(API_URL + `/updateProperty?id=${formData.id}`, formData);

                if (response.status === 200) {
                    console.log('villa updated successfully');
                    window.alert('villa updated successfully!')
                    navigate('/PropertyDatabase')
                } else {
                    console.error('Error updating user');
                }
            } catch (error) {
                console.error(error);
            }

    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>Edit Property</p>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Category</p>
                        <select className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='property_type' value={formData.property_type} onChange={handleInputChange}>
                            <option value=''  disabled hidden>Select</option>
                            <option value='Villa'>Villa</option>
                            <option value='Apartment'>Apartment</option>
                        </select>
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Name</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaName' value={formData.villaName} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Headline</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaHeadline' value={formData.villaHeadline} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Contetnt</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaContent' value={formData.villaContent} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Location</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='location' value={formData.location} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Price/ Night</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='price' value={formData.price} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Adults</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_adults' value={formData.max_adults} onChange={handleInputChange} />
                    </div>
                    
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Child</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_child' value={formData.max_child} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Bedrooms</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_bedrooms' value={formData.max_bedrooms} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Bathroom</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_bathrooms' value={formData.max_bathrooms} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Taxes</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='taxes' value={formData.taxes} onChange={handleInputChange} />
                    </div>
                </div>

                <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                    <button className="cancel_btn" onClick={() => navigate('/PropertyDatabase')}>Cancel</button>

                    <button className="done_btn" onClick={() => updateProperty()}>Done</button>
                </div>

            </div>
        </div>
    );
};

export default EditProperty;