import React, { useState } from 'react';
import '../Dashboard/Dashboard.css';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';
import { useAuth } from '../../AuthContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const Login = () => {

  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        const data = await response.json();
        if (data.user.role === 'Admin') {
          dispatch({ type: 'SIGN_IN', payload: { user: data.user } });
          navigate('/Dashboard');
          setLoading(false);
        } else {
          alert('Access denied. Only admins can sign in.');
          setLoading(false);
        }
      } else if (response.status === 401) {
        alert('Invalid credentials.');
        setLoading(false);
      } else {
        alert('Login failed');
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const [isSignup] = useState(false);
  // const toggleForm = () => {
  //   setIsSignup(!isSignup);
  // };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  return (
    <div className=''>
      <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>

        <div className='flex justify-center items-center py-[3%] lg:py-[1%] border-b-[1px] border-[#877D6F] mx-[3%]'>
          <a href="https://sohamvillas.com/"><img src="../assets/soham_logo_new.webp" alt="Logo" className='h-7 lg:h-9' /></a>
        </div>

        <h1 className='text-center text-xl md:text-2xl 2xl:text-3xl mt-[9%] mb-[5%] lg:mt-[4%] lg:mb-[2%]'>Admin Account</h1>

        <div className="container_SignIn">
          {/* <div className={`slider ${isSignup ? 'moveslider' : ''}`}></div> */}
          <div className="btn">
            <button className={`login ${!isSignup ? 'active' : ''}`} >SIGN IN</button>
            {/* <button className={`signup ${isSignup ? 'active' : ''}`} onClick={() => toggleForm()}>FORGOT PASSWORD</button> */}
          </div>
          <div className={`form-section ${isSignup ? 'form-section-move' : ''}`}>
            <form className="login-box" onSubmit={handleSignIn}>
              <div className='my-[4%] lg:my-[2%] w-[100%]'>
                <label class="block text-sm font-medium">Email*</label>
                <div class="mt-1 border-b border-gray-300">
                  <input
                    type="text"
                    class="block w-full border-0 border-b border-transparent bg-[#ffffff] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                    placeholder="Enter email"
                    required
                    name="email" value={formData.email} onChange={handleInputChange} />
                </div>
              </div>

              <div className='my-[4%] lg:my-[2%] w-[100%]'>
                <label className="block text-sm font-medium">Password*</label>
                <div className="relative mt-1 border-b border-gray-300">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    className="block w-full border-0 border-b border-transparent bg-[#ffffff] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                    placeholder="Enter password"
                    required
                    value={formData.password} 
                    onChange={handleInputChange}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
              </div>

              {loading ?
                <button className='flex w-full justify-center my-[8%] lg:my-[5%] border-[1px] border-[#333333] rounded-full px-[4%] py-[0.3%] bg-[#333333] text-white hover:bg-transparent hover:text-[#333333] lg:text-[14px] 2xl:text-2xl' onClick={handleInputChange} disabled style={{ opacity: 0.5 }}>
                  <span>Sign In</span>
                </button> :
                <button className='flex w-full justify-center my-[8%] lg:my-[5%] border-[1px] border-[#333333] rounded-full px-[4%] py-[0.3%] bg-[#333333] text-white hover:bg-transparent hover:text-[#333333] lg:text-[14px] 2xl:text-2xl' onClick={handleInputChange}>
                  <span>Sign In</span>
                </button>
              }
            </form>

            <div className="signup-box">
              <div className='my-[4%] lg:my-[2%] w-[100%]'>
                <label class="block text-sm font-medium">Email*</label>
                <div class="mt-1 border-b border-gray-300">
                  <input
                    type="text"
                    name="email"
                    class="block w-full border-0 border-b border-transparent bg-[#ffffff] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                    placeholder="Enter first name" />
                </div>
              </div>

              <div className='my-[4%] lg:my-[2%] w-[100%]'>
                <label class="block text-sm font-medium">Phone Number*</label>
                <div class="mt-1 border-b border-gray-300">
                  <input
                    type="text"
                    name="phoneNumber"
                    class="block w-full border-0 border-b border-transparent bg-[#ffffff] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                    placeholder="Enter phone number" />
                </div>
              </div>

              <div className='my-[4%] lg:my-[2%] w-[100%]'>
                <label class="block text-sm font-medium">Paasword*</label>
                <div class="mt-1 border-b border-gray-300">
                  <input
                    type="text"
                    name="password"
                    class="block w-full border-0 border-b border-transparent bg-[#ffffff] outline-[#877D6F] py-[1%] px-[3%] lg:px-[2%]"
                    placeholder="Enter password" />
                </div>
              </div>

              <button className='flex w-full justify-center my-[5%] lg:my-[2%] border-[1px] border-[#333333] rounded-full px-[4%] py-[0.3%] bg-[#333333] text-white hover:bg-transparent hover:text-[#333333] lg:text-[14px] 2xl:text-2xl'>
                GET&nbsp;OTP
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Login;