import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';

const AddManualBooking = () => {
    const [villas, setVillas] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        adults: '',
        children: '',
        property_type: '',
        villaName: '',
        villaId: '',
        checkIn: '',
        checkOut: '',
        price: '',
        pendingPrice: 0,
        taxes: 0,
        discount: 0,
        total: '',
        payment_status: '',
        booking_status: '',
        booking_portal: '',
        specialRequests: ''
    });

    const navigate = useNavigate();
    const fetchRooms = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(API_URL + '/roomsController');
            const data = await response.json();
            setVillas(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching rooms:', error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRooms();
    }, [fetchRooms]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'villaName') {
            const selectedVilla = villas.find(villa => villa.villaName === value);
            setFormData({
                ...formData,
                [name]: value,
                villaId: selectedVilla ? selectedVilla.id : ''
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.property_type || !formData.villaName || !formData.payment_status || !formData.booking_portal) {
            alert('Please fill in all mandatory fields.');
            return;
        }

        try {
            const response = await fetch(API_URL + '/addBooking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            if (response.ok) {
                alert('Booking added successfully');
                navigate('/ManualBooking');
            } else {
                alert(`Failed to add booking: ${result.error}`);
            }
        } catch (error) {
            console.error('Error adding booking:', error);
            alert('Failed to add booking');
        }
    };

    const handleClose = () => {
        navigate('/ManualBooking');
    };
    
    const calculateTotalAmount = useCallback(() => {
        const { price, pendingPrice, taxes, discount } = formData;
        const finalTotal = parseFloat(price) + parseFloat(pendingPrice) + parseFloat(taxes) - parseFloat(discount);
        return finalTotal;
    }, [formData]);

    useEffect(() => {
        const finalTotal = calculateTotalAmount();
        setFormData((prevFormData) => ({
            ...prevFormData,
            total: finalTotal.toFixed(), // To set total with 2 decimal places
        }));
    }, [formData, calculateTotalAmount]);

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
                </div>
            ) : null}
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>Add Custom Booking</p>

                <form onSubmit={handleSubmit}>
                    <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Villa/Apartment Name *</p>
                            <select
                                name="villaName"
                                value={formData.villaName}
                                onChange={handleChange}
                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                                required
                            >
                                <option value='' disabled hidden>Select</option>
                                {villas.map((villa, index) => (
                                    <option key={index} value={villa.villaName}>
                                        {villa.villaName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Full Name</p>
                            <input type="text" name="name" value={formData.name} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Email ID</p>
                            <input type="email" name="email" value={formData.email} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Phone Number</p>
                            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Adults</p>
                            <input type="number" name="adults" value={formData.adults} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Children</p>
                            <input type="number" name="children" value={formData.children} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-2 items-center'>
                        <div className='m-[2%] lg:mx-[3%] lg:w-[60%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Type *</p>
                            <select name="property_type" value={formData.property_type} onChange={handleChange} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' required>
                                <option value='' disabled hidden>Select</option>
                                <option value='Villa'>Villa</option>
                                <option value='Apartment'>Apartment</option>
                            </select>
                        </div>

                        <div className='m-[2%] lg:mx-[3%] lg:-ml-[30%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Special Requests</p>
                            <input type="text" name="specialRequests" value={formData.specialRequests} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check In</p>
                            <input type="date" name="checkIn" value={formData.checkIn} onChange={handleChange} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check Out</p>
                            <input type="date" name="checkOut" value={formData.checkOut} onChange={handleChange} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Advance Amount</p>
                            <input type="text" name="price" value={formData.price} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Balance Amount</p>
                            <input type="text" name="pendingPrice" value={formData.pendingPrice} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Taxe Amount</p>
                            <input type="text" name="taxes" value={formData.taxes} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Discount Amount</p>
                            <input type="text" name="discount" value={formData.discount} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Total Amount</p>
                            <input type="text" name="total" value={formData.total} onChange={handleChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Payment Status *</p>
                            <select name="payment_status" value={formData.payment_status} onChange={handleChange} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' required>
                                <option value='' disabled hidden>Select</option>
                                <option value='PAYMENT_PENDING'>PENDING</option>
                                {/* <option value='PAYMENT_PARTIAL'>PARTIAL</option> */}
                                <option value="PAYMENT_SUCCESS">SUCCESS</option>
                            </select>
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Status</p>
                            <select name="booking_status" value={formData.booking_status} onChange={handleChange} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
                                <option value='' disabled hidden>Select</option>
                                {/* <option value='PENDING'>PENDING</option> */}
                                <option value="SUCCESS">SUCCESS</option>
                                <option value="CANCEL">CANCEL</option>
                            </select>
                        </div>

                        <div className='m-[2%] lg:mx-[5%]'>
                            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Portal *</p>
                            <select name="booking_portal" value={formData.booking_portal} onChange={handleChange} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
                                <option value='' disabled hidden>Select</option>
                                <option value='OTA'>OTA</option>
                                <option value='Agents'>Agents</option>
                                <option value='Direct'>Direct</option>
                                <option value='Website'>Website</option>
                            </select>
                        </div>
                    </div>

                    <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                        <button type="button" onClick={handleClose} className="cancel_btn">Close</button>
                        <button type="submit" className="done_btn">Done</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddManualBooking;
