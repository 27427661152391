import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../Config';

const EditCoupon = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const couponDetails = location.state?.couponDetails || '';

    const [formData, setFormData] = useState({
        id: couponDetails.id || '',
        coupon_name: couponDetails.coupon_name || '',
        discount_percentage: couponDetails.discount_percentage || '',
        expiryDate: couponDetails.expiryDate || ''

    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const updateCoupon = async () => {
            try {
                const response = await axios.put(API_URL + `/updateCoupon?id=${formData.id}`, formData);

                if (response.status === 200) {
                    console.log('coupon updated successfully');
                    window.alert('coupon updated successfully!')
                    navigate('/GenerateCoupon')
                } else {
                    console.error('Error updating coupon');
                }
            } catch (error) {
                console.error(error);
            }

    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>Add Coupon</p>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Code</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='coupon_name' value={formData.coupon_name} onChange={handleInputChange}/>
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Discount Percentage</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='discount_percentage' value={formData.discount_percentage} onChange={handleInputChange}/>
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Expiry Date</p>
                        <input type="date" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='expiryDate' value={formData.expiryDate} onChange={handleInputChange}/>
                    </div>
                </div>

                <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                    <button className="cancel_btn" onClick={() => navigate('/GenerateCoupon')}>Cancel</button>

                    <button className="done_btn" onClick={() => updateCoupon()}>Done</button>
                </div>

            </div>
        </div>
    );
};

export default EditCoupon;