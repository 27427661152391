import React from 'react';
import Menu from '../Menu/Menu';

const Settings = () => {
    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>

                <div className='flex flex-col lg:flex-row justify-between lg:items-center mx-[3%] md:mx-[1%] mb-[2%]'>
                    <div className='flex items-center justify-between'>
                        <p className='font-semibold'>Settings</p>

                        {/* <button className='lg:hidden flex items-center ml-2 h-[35px] border-[1px] border-[#2F65B8] rounded-lg px-2 text-[#2F65B8] text-[12px] bg-white hover:bg-[#2F65B8] hover:text-white' onClick={handleAddUser}>
                            Add&nbsp;User
                        </button> */}
                    </div>

                    {/* <div className='flex my-[2%] lg:my-0'>
                        <div className="p-2 px-5 overflow-hidden w-[50px] h-[35px] hover:w-[250px] bg-white shadow-sm rounded-lg flex group items-center hover:duration-300 duration-300 cursor-pointer">
                            <div className="flex items-center justify-center fill-[#2F65B8]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Isolation_Mode"
                                    data-name="Isolation Mode"
                                    viewBox="0 0 24 24"
                                    width="12"
                                    height="12"
                                >
                                    <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                className="outline-none text-xs bg-transparent w-full text-gray-500 font-normal px-4"
                                placeholder='Search here...'
                            />
                        </div>

                        <button className="download_btn ml-2">
                            <svg className="svgIcon" viewBox="0 0 384 512" height="1rem" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                            <span className="icon2"></span>
                            <span className="tooltip">Download</span>
                        </button>

                        <button className='flex items-center ml-2 h-[35px] border-[1px] border-[#2F65B8] rounded-lg px-2 text-[#2F65B8] text-[12px] bg-white hover:bg-[#2F65B8] hover:text-white'>
                            Filter<BsFilterLeft className='ml-1 text-[14px]' />
                        </button>

                        <div className='hidden lg:block'>
                            <button className='flex items-center ml-2 h-[35px] border-[1px] border-[#2F65B8] rounded-lg px-2 text-[#2F65B8] text-[12px] bg-white hover:bg-[#2F65B8] hover:text-white' onClick={handleAddUser}>
                                Add&nbsp;User
                            </button>
                        </div>
                    </div> */}
                </div>

            </div>
        </div>
    );
};

export default Settings;